import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _createSvgIcon2 from "../../createSvgIcon";
import * as _CloseCircle3 from "@rsuite/icon-font/lib/legacy/CloseCircle";

var _CloseCircle2 = "default" in _CloseCircle3 ? _CloseCircle3.default : _CloseCircle3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(_createSvgIcon2);

var _CloseCircle = _interopRequireDefault(_CloseCircle2); // Generated by script, don't edit it please.


var CloseCircle = (0, _createSvgIcon["default"])({
  as: _CloseCircle["default"],
  ariaLabel: "close circle",
  category: "legacy",
  displayName: "CloseCircle"
});
var _default = CloseCircle;
exports["default"] = _default;
exports = exports.default;
export default exports;