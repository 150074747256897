import _CloseCircle2 from "../lib/icons/legacy/CloseCircle";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _CloseCircle["default"];
  }
});

var _CloseCircle = _interopRequireDefault(_CloseCircle2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule;